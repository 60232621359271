import React from "react";
import winatripLook from "../Images/WinATrip_look.png";
function EmailBox() {
  return (
    <div className="row">
    <div className="col-md-1">
      <img src={winatripLook} alt="WinATrip_look.png"/>
    </div>
    <div className="col-md-11" style={{ fontFamily: "Arial" }}>
      <h2>BE ON THE LOOK OUT!</h2>
      Expect an email within 5 business days from{" "}
      <a href="mailto:do-not-reply@bhnetwork.com">
        {process.env.REACT_APP_DESC_EMAIL}
      </a>{" "}
      or{" "}<br/>
      <a href="mailto:do-not-reply@wgiftcard.com">
        {process.env.REACT_APP_DESC_GIFTCARD}
      </a>{" "}
      - we suggest adding these email addresses to your SAFE SENDER list in
      your email settings.
    </div>
  </div>
  );
}

export default EmailBox;
