import React from "react";
import winatripGift from "../Images/WinATrip_gift.png";
function GiftBox() {
  return (
    <div className="row">
    <div className="col-md-1">
      <img
        src={winatripGift}
        alt="WinATrip_gift.png"
      />
    </div>

    <div className="col-md-11" style={{ fontFamily: "Arial" }}>
      Once you receive your email from{" "}
      <a href="mailto:do-not-reply@bhnetwork.com">
      {process.env.REACT_APP_DESC_EMAIL}
      </a>{" "}
      or{" "}<br/>
      <a href="mailto:do-not-reply@wgiftcard.com">
      {process.env.REACT_APP_DESC_GIFTCARD}
      </a>
      , you can use the card virtually!
    </div>
  </div>
  );
}
export default GiftBox;
